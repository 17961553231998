import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';
import { v1 as uuid } from 'uuid';

import {
  changeReportType,
  clearReport,
  getAuthInfoRequest,
  resetReport
} from '../../../store/actions';
import { IRootState } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';

import ReportTableGPR from '../../../components/GPR/ReportTableGPR/ReportTableGPR.component';
import { customStyles } from '../../../components/GPR/ReportTableGPR/components/CustomDropdown.component';

import './ReportGPR.scss';
import background from '../../../assets/images/gpr/background.png';
import closeImage from '../../../assets/images/gpr/closeModal.svg';
import spiner from '../../../assets/images/gpr/orange_spiner.gif';
import { ReactComponent as ViewReportsSvg } from '../../../assets/images/viewBatches.svg';
import LeaveConfirmationModal from '../../../components/Modals/LeaveConfirmationModal/LeaveConfirmationModal.component';

type ReportGPRProps = {
  openApiModal: () => void;
};

const ReportGPR = ({ openApiModal }: ReportGPRProps) => {
  const [calculatingStatus, setCalculatingStatus] = useState('done');
  const [isOpenProcessModal, setIsOpenProcessModal] = useState(false);

  const { activeReportType } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const { activeSingleReport } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const { activeMultiReport } = useSelector((state: IRootState) => state.gprCalculatorReducer);
  const user = useSelector((state: IRootState) => state.authReducer);

  const { id: singleReportId, data: singleReportData } = activeSingleReport;
  const { id: multiReportId, data: multiReportData } = activeMultiReport;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [leaveModalIsOpen, setLeaveModalIsOpen] = useState(false);

  const isReportActive = (report, reportType) => {
    if (report.data.length > 1) {
      return true;
    }

    if (report.data.length === 1) {
      const row = report.data[0];

      if (reportType === 'single') {
        return row.address || row.bathrooms || row.bedrooms || row.currentRent;
      }

      if (reportType === 'multi') {
        return (
          report.address ||
          row.address ||
          row.description ||
          row.bathrooms ||
          row.bedrooms ||
          row.count
        );
      }
    }

    return false;
  };

  const isActiveReport =
    activeReportType === 'single'
      ? isReportActive(activeSingleReport, activeReportType)
      : isReportActive(activeMultiReport, activeReportType);

  const clearCurrentReport = () => {
    dispatch(clearReport({ activeReportType }));
  };

  const openLeaveModal = () => {
    if (isActiveReport) {
      setLeaveModalIsOpen(true);
    } else {
      handleCompletedReportsClick();
    }
  };

  const closeLeaveModal = () => setLeaveModalIsOpen(false);

  const handleCompletedReportsClick = () => {
    clearCurrentReport();
    navigate('/gpr-calculator');
  };

  useEffect(() => {
    dispatch(getAuthInfoRequest({ authKey: user.apiKey }));
  }, [isOpenProcessModal]);

  const openProcessModal = () => setIsOpenProcessModal(true);
  const closeProcessModal = () => setIsOpenProcessModal(false);

  const changeReport = (value: string, e) => {
    e.preventDefault();
    dispatch(changeReportType(value));
  };

  const handleResetReport = () => {
    const id = uuid();
    dispatch(resetReport({ activeReportType, id }));
  };

  const isDisabled = () => {
    if (activeReportType === 'single') {
      return !!(
        singleReportData?.length <= 0 ||
        user.credits_remaining < singleReportData.length ||
        singleReportData.find((item) => item.address === '' || item.address === null) ||
        singleReportData.find((item) => item.bathrooms === '') ||
        singleReportData.find((item) => item.bedrooms === '')
      );
    } else {
      return !!(
        multiReportData?.length <= 0 ||
        user.credits_remaining < multiReportData.length ||
        activeMultiReport.address === '' ||
        activeMultiReport.address === null ||
        multiReportData.find((item) => item.bathrooms === '') ||
        multiReportData.find((item) => item.bedrooms === '') ||
        multiReportData.find((item) => item.count === '')
      );
    }
  };

  const generateTitle = () => {
    let date = new Date();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let year = date.getFullYear();
    let hour = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');
    return `GPR ${year}/${month}/${day} ${hour}:${minute}:${second}`;
  };

  const onCalculate = async () => {
    setCalculatingStatus('loading');
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        activeReportType,
        createdAt: new Date().toISOString(),
        title: generateTitle(),
        reportData: activeReportType === 'single' ? activeSingleReport : activeMultiReport
      })
    };
    const response = await fetch(`${ENVS.PROD}/gpr/${user.apiKey}`, requestOptions);
    const data = await response.json();
    setCalculatingStatus('done');
    navigate(
      `/gpr-calculator/result/${activeReportType === 'single' ? singleReportId : multiReportId}`
    );
  };

  const lackOfAPIkeyMessage = user.authorized ? null : (
    <div className="creditsAvailabilityContainer">
      <span className="creditsAvailabilityText">
        To run the report, please enter the API key.
        <span onClick={openApiModal} className="creditsAvailabilityLink">
          Set API key
        </span>
      </span>
    </div>
  );

  const lackOfCreditsMessage =
    user.credits_remaining >
    (activeReportType === 'single' ? singleReportData.length : multiReportData.length) ? null : (
      <div className="creditsAvailabilityContainer">
        <span className="creditsAvailabilityText">
          The number of items exceeds the available credits.
          <a
            href="https://www.rentometer.com/accounts/edit"
            target="_blank"
            rel="noreferrer"
            className="creditsAvailabilityLink">
            Buy More Credits
          </a>
        </span>
      </div>
    );

  return (
    <div className="container" style={{ backgroundImage: `url(${background})` }}>
      <div className="_container">
        {calculatingStatus === 'loading' ? (
          <div className="spiner-container">
            <img src={spiner} alt="loading.." />
          </div>
        ) : (
          <>
            <div className="toggle-conteiner">
              <div className="toggle-conteiner__header">
                <div className="toggle__family">
                  <button
                    className={`toggle_btn ${
                      activeReportType === 'single' ? 'toggle_btn-active' : ''
                    }`}
                    onClick={(e) => changeReport('single', e)}>
                    Single-family houses
                  </button>
                  <button
                    className={`toggle_btn ${
                      activeReportType === 'multi' ? 'toggle_btn-active' : ''
                    }`}
                    onClick={(e) => changeReport('multi', e)}>
                    Multi-family building
                  </button>
                </div>
                <button className="contentHeaderBtn" onClick={openLeaveModal}>
                  <ViewReportsSvg />
                  View History
                </button>
              </div>
            </div>
            <LeaveConfirmationModal
              isOpen={leaveModalIsOpen}
              onRequestClose={closeLeaveModal}
              onConfirm={handleCompletedReportsClick}
            />
            <div className="content">
              <h2 className="header__titleMobile">Rentometer GPR Calculator</h2>
              <div className="content__header">
                <h2 className="header__title">Rentometer GPR Calculator</h2>
                <div className="button__container">
                  <button className="header__buttonReset" onClick={handleResetReport}>
                    Clear All
                  </button>
                  <div className="button__calculate">
                    <button
                      className="header__button"
                      onClick={openProcessModal}
                      disabled={isDisabled()}>
                      Calculate GPR{activeReportType === 'single' ? ' (SFR)' : ' (MF)'}
                    </button>
                    <span className="hide">
                      {user.authorized
                        ? 'Please fill empty addresses or delete them'
                        : 'Please set the API key to start the report'}
                    </span>
                  </div>
                </div>
              </div>
              <ReportTableGPR />
              {lackOfAPIkeyMessage}
              {user.authorized ? lackOfCreditsMessage : null}
            </div>
            <div className="description-container">
              <div className="description__item description__item-small">
                Gross Potential Rent is a financial metric used to evaluate the amount of income
                that a property could potentially produce.
              </div>
              <div className="description__item description__item-big">
                Gross Potential Rent is calculated as the amount of rental income a property could
                produce assuming all units were rented at market rates, and all tenants paid their
                rent on time each month.
              </div>
            </div>
            <Modal
              isOpen={isOpenProcessModal}
              onRequestClose={closeProcessModal}
              style={customStyles}
              shouldCloseOnOverlayClick={false}>
              <img
                src={closeImage}
                alt="close modal icon"
                className="closeModalIcon"
                onClick={() => closeProcessModal()}
              />
              <h1 className="processModalTitle">Start process</h1>
              <span className="processModalDescription">
                Your credits: {user.credits_remaining}
              </span>
              <span className="processModalDescription">
                Process cost:{' '}
                {activeReportType === 'single' ? singleReportData.length : multiReportData.length}
              </span>
              <div className="modalButtonContainer">
                <button onClick={() => closeProcessModal()} className="closeProcessModalButton">
                  Cancel
                </button>
                <button onClick={() => onCalculate()} className="confirmProcessModalButton">
                  Confirm
                </button>
              </div>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default ReportGPR;
